import React from "react";
import { Col, Row } from "react-bootstrap";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql, Link } from "gatsby";

const TagsPage = ({ data }) => (
  <Layout>
    <SEO title="タグ一覧" />
    <Row style={{ maxWidth: `60rem` }}>
      <Col xs={12} md={12} >
        <h5>
          {data.allMicrocmsTag.edges.map(({ node }) => (
            <Link to={`/tag/${node.tagId}`} style={{ margin: `0.5rem` }} key={node.tagId} >
              {`#` + node.tagname}
            </Link>
          ))}
        </h5>
      </Col>
    </Row>
  </Layout>
);

export default TagsPage;

export const query = graphql`
query {
  allMicrocmsTag {
    edges {
      node {
        tagId
        tagname
      }
    }
  }
}
`